@charset 'utf-8';
@import 'settings';

@import '_components/slider';

html {
	.news.aktuelles {
		border: none;

		[class*="news-list-view"] .article .header h3 {
			border: none;
		}
	}

	.news {
		border-top: 1px solid $lightgray;
		//margin: 0 -.9375rem;
		//max-width: 1170px;
		/*
		.news-list-view {
			grid-template-columns: 50% 50%;
			display: grid;
		}*/

		[class*="news-list-view"] {
			padding: 1rem 0;
			//grid-gap: 1rem;

			.article:nth-child(2n+1) {
				grid-column: 1;
			}

			.article:nth-child(2n) {
				grid-column: 2;
			}

			.article.aktuelles .news-img-wrap img {
				width: auto;
			}

			.article {
				width: 100%;
				max-width: 100%;
				margin: 0;
				/*
				flex: 0 0 47.25%;
				max-width: 47.25%;
				border-top: 1px solid $lightgray;
				padding: 1rem 0 4.5rem;
				margin-bottom: 0;
*/
				//position: relative;
				.news-img-wrap {
					margin: 0 0 1.5rem;
					float: none;

					a {
						display: inline-block;
						float: none;
						border: none;
						padding: 0;
						width: 100%;

						img {
							width: 100%;
						}
					}
				}

				.header h3 {
					text-decoration: none;
					border-bottom: 2px dotted;
					font-family: $body-font-familyBold;
				}

				.teaser-text {
					margin: 0;
				}

				.footer {
					border: none;
					margin: 0;
					padding: 0;
					color: inherit;
					//position: absolute;
					//bottom: 0;
					//width: 100%;
				}

				p {
					margin: 0;
					font-size: 1.125rem;
					line-height: 1.6;
				}

				a {
					display: block;
					color: $darkgray;
				}

				a:focus,
				a:hover {
					color: $darkblue;
				}

				a::after {
					display: none;
				}
			}

			.article:first-child,
			.article:nth-child(2) {
				border-top: none;
			}

			.page-navigation {
				width: 100%;
				//display: block;
				padding: .9375rem;
				grid-column: 1/3;

				p {
					display: none;
				}

				ul {
					width: 100%;
					//display: block;
					li {
						flex: 0 0 auto;
					}
				}
			}
		}

		.green.news-list-view {
			.article {
				a:focus,
				a:hover {
					color: $green;
				}

				.frame-secondRow-75 {
					grid-row: 3/10;
				}
			}

			.frame-secondRow-75-detail {
				// grid-row: 2/10;
				//border: 1px solid red;
			}
		}

		.progress-status {
			margin: 1.125rem 0;

			label {
				font-size: 1.125rem;
				padding: 0;
				margin: 0;
			}

			.progress {
				//padding: 0;
				margin: 0;
				padding: 2px;
				border: 2px solid $lightgray;
				background: white;
				height: 2.5rem;
				position: relative;
				max-width: 99%;

				span.progress-meter {
					margin: 0 auto;
					padding: 0;
					height: 2rem;
					float: left;
					background: -moz-linear-gradient(0% 50% 0deg, $lightgreen 0%, $darkgreen 100%);
					background: -webkit-linear-gradient(0deg, $lightgreen 0%, $darkgreen 100%);
					background: -webkit-gradient(linear, 0% 50%, 100% 50%, color-stop(0, $lightgreen), color-stop(1, $darkgreen));
					background: -o-linear-gradient(0deg, $lightgreen 0%, $darkgreen 100%);
					background: -ms-linear-gradient(0deg, $lightgreen 0%, $darkgreen 100%);
					// -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr='$lightgreen', endColorstr='$darkgreen' ,GradientType=0)";
					background: linear-gradient(90deg, $lightgreen 0%, $darkgreen 100%);
					//filter: progid:DXImageTransform.Microsoft.gradient(startColorstr= '$lightgreen',endColorstr='$darkgreen' , GradientType=1) z-index: 10;
				}

				.status {
					position: absolute;
					top: 0;
					z-index: 1000;
					display: block;
					width: 100%;
					height: 100%;
					padding: 3px 5px 5px;
					z-index: 11;
				}
			}
		}
	}

	.news-single {
		padding-top: 1rem;
		//margin: 0;
		.orbit {
			.orbit-controls {
				.orbit-next,
				.orbit-previous {
					margin-top: 0;
				}
			}
		}

		.orbit.aktuelles {
			//height: 100%;

			.orbit-wrapper {
				//height: 100%;

				.orbit-container {
					//height: 100% !important;

					.orbit-slide {
						//height: 100%;

						.orbit-figure {
							//height: 100%;

							.orbit-image {
								width: 100%;
								max-width: 100%;
								/*
								height: 100%;
								width: auto;
								max-width: none;
								*/
							}
						}
					}
				}
			}
		}

		.header.frame-firstRow-33 {
			display: table;
			//height: 30rem;
			.table {
				display: table-cell;
				vertical-align: middle;

				p:last-child {
					margin-bottom: 0;
				}
			}
		}

		.ce-gallery img {
			width: 100%;
		}

		.footer {
			display: none;
		}

		.article-header {
			border-bottom: 1px solid $lightgray;
			padding-bottom: 3rem;
			margin-bottom: 4rem;
		}

		.article-header.no {
			border-bottom: none;
			margin-bottom: 0;
			padding-bottom: 0;
		}

		.article .article-header {
			display: grid;
			//grid-template-columns: 8.3333% 8.3333% 8.3333% 8.3333% 8.3333% 8.3333% 8.3333% 8.3333% 8.3333% 8.3333% 8.3333% 8.3333%;
			//grid-template-columns: 8.25% 8.25% 8.25% 8.25% 8.25% 8.25% 8.25% 8.25% 8.25% 8.25% 8.25% 8.25%;
			grid-template-columns: repeat(12, 1fr);
			grid-gap: 3rem;
			box-sizing: border-box;
			max-width: 1170px;

			.frame-firstRow-66 {
				grid-row: 1;
				grid-column: 1/8;
			}

			.frame-firstRow-66 + .frame-firstRow-33 {
				grid-row: 1;
				grid-column: 8/13;
			}

			.frame-firstRow-33 {
				grid-row: 1;
				grid-column: 1/13;
			}
		}

		.article .article-body {
			display: grid;
			grid-template-columns: repeat(12, 1fr);
			grid-column-gap: 3rem;
			grid-row-gap: 0;
			box-sizing: border-box;
			max-width: 1170px;

			.frame .ce-bodytext {
				//padding: 0;
			}

			.frame-default {
				grid-column: 1/13;
			}

			.frame-secondRow-75 {
				grid-column: 1/9;
				/*
				width: 65%;
				float: left;
*/
			}

			.frame-secondRow-75:nth-child(1) {
				grid-row: 1/10;
			}

			.frame-secondRow-75:nth-child(2) {
				//	grid-row: 2/10;
			}

			.frame-secondRow-25 {
				grid-column: 9/13;
				width: 100%;
				/*
				width: 30%;
				float: right;
*/
				a span:focus,
				a span:hover,
				a::after,
				a:focus,
				a:hover {
					color: $darkblue !important;
				}
			}

			.frame-secondRow-25:nth-child(2) {
				grid-row: 1;
			}

			.frame-secondRow-25:nth-child(3) {
				grid-row: 2;
			}

			.frame-secondRow-25:nth-child(4) {
				grid-row: 3;
			}

			.frame-secondRow-25:nth-child(5) {
				grid-row: 4;
			}

			.frame-secondRow-25:nth-child(5) {
				grid-row: 5;
			}
		}

		h6 {
			border-bottom: 2px dotted;
		}

		.news-related-wrap {
			grid-column: 9/12;
			grid-row: 2/2;
			display: block;
			margin: 0 0 2rem 1.5rem;

			.news-related {
				border: none;
				width: 100%;
				padding: 0;
				display: block;

				ul {
					margin-left: 2.125rem;

					a {
						font-family: $body-font-familyBold;
					}

					a::before {
						margin: .125rem 1rem .125rem -.5rem;
					}
				}
			}
		}

		.shariff {
			grid-column: 1/12;
			display: block;

			a,
			span {
				color: white;
			}
		}

		.icon-spenden-neg::before {
			font-size: 5.5rem;
			vertical-align: middle;
			margin-top: -.5rem;
		}
	}

	.news.news-single.green {
		.button.donation {
			background: $green;
			color: white;
			font-family: $body-font-familyBold;
			font-size: 2.5rem;
			text-transform: uppercase;
			text-align: left;
			padding: 0;
			box-shadow: $boxshadow;
		}

		.button.donation:focus,
		.button.donation:hover {
			background: $darkgreen;
		}

		.frame-secondRow-75:nth-child(1) {
			grid-row: 1;
		}

		.frame-secondRow-75:nth-child(2) {
			grid-row: 2/10;
		}

		.frame-secondRow-25 {
			grid-column: 9/13;
			/*
					width: 30%;
					float: right;
*/
			a span:focus,
			a span:hover,
			a::after,
			a:focus,
			a:hover {
				color: $green !important;
			}
		}

		.frame-secondRow-25:nth-child(2) {
			grid-row: 2;
		}

		.frame-secondRow-25:nth-child(3) {
			grid-row: 3;
		}

		.frame-secondRow-25:nth-child(4) {
			grid-row: 4;
		}

		.frame-secondRow-25:nth-child(5) {
			grid-row: 5;
		}

		.frame-secondRow-25:nth-child(5) {
			grid-row: 5;
		}

		.news-backlink-wrap {
			a:focus,
			a:hover {
				color: $green;
			}
		}
	}

	.news-backlink-wrap {
		clear: both;
		margin-top: 4rem;
		grid-column: 1/13;

		a {
			color: $darkgray;
			font-size: 1.125rem;
			text-align: center;
			text-transform: uppercase;
			display: block;
			font-family: $body-font-familyBold;
		}

		a:focus,
		a:hover {
			color: $darkblue;
		}

		a::before {
			content: "‹‹‹";
		}

		a::after {
			display: none;
		}
	}

	.powermail_fieldset {
		.powermail_fieldwrap_writeto div {
			font-family: $body-font-familyBold;
			font-size: 1.25rem;
			text-transform: uppercase;
			color: $darkgray;
		}

		.layout1 {
			width: 100%;
			padding: 0 .9375rem;
		}

		.layout2 {
			width: 70%;
			padding: 0 .9375rem;
		}

		.layout3 {
			width: 30%;
			padding: 0 .9375rem;
		}

		.nolabel {
			display: none;
		}

		label {
			font-family: $body-font-familyBold;
			font-size: 1.25rem;
			text-transform: uppercase;
			color: $darkgray;
		}

		input[type="submit"] {
			width: 100%;
			background: $blue;
			color: white;
			border: 2px solid $blue;
			text-transform: uppercase;
			font-size: 1.25rem;
			padding: .5rem;
		}

		input[type="submit"]:hover,
		input[type="submit"]:focus {
			background: white;
			color: $blue;
		}

		textarea {
			height: 9.875rem;
		}
	}

	.element1cols[class*="frame-box-line-topbottom"] {
		// padding: 0 .9375rem;
		border-top: 1px solid $lightgray;
		border-bottom: 1px solid $lightgray;
		padding: 4.25rem 0;
		margin: 0 1rem 4rem;
		width: auto;
	}

	.frame-tt-address {
		.vcard {
			h4 {
				text-transform: none;
				font-family: $body-font-family;
				font-size: 1.125rem;
			}

			address {
				font-family: $body-font-family;
				font-size: 1.125rem;
				font-style: normal;
			}
		}
	}

	.tabs {
		border: none;
		max-width: 1170px;
		margin: 0 auto !important;

		.tabs-title {
			a {
				padding: 0 .5rem 0 0;
				margin: 0 .5rem 0 0;
				border-right: 2px solid $darkgray;
				font-size: 1.5rem;
				font-family: $body-font-familyBold;
				color: $darkgray;
			}

			a::after,
			a::before {
				display: none;
			}

			a:focus,
			a:hover {
				color: $darkblue;
				background: none;
			}
		}

		.tabs-title:last-child a {
			border: none;
		}

		.tabs-title.is-active {
			a {
				color: $darkblue;
				background: none;
			}
		}
	}

	.tabs-content {
		border: none;
		max-width: 1170px;
		margin: 0 auto;

		.tabs-panel {
			padding: 0;
		}
	}

	.accordion {
		border: none;

		.deeplink::after,
		.deeplink::before {
			display: none;
		}

		.deeplink h2::after {
			display: inline-block;
			float: right;
			width: 10px;
			height: 10px;
			content: " ";
			width: 0;
			height: 0;
			margin-top: 1rem;
			border-left: 10px solid transparent;
			border-right: 10px solid transparent;
			border-bottom: none;
			border-top: 16px solid $darkgray;
			transition: border .5s, opacity .5s, visibility .5s;
			z-index: 1000;
		}

		.is-active .deeplink h2::after {
			border-left: 10px solid transparent;
			border-right: 10px solid transparent;
			border-bottom: 16px solid $darkgray;
			border-top: none;
			transition: border .5s, opacity .5s, visibility .5s;
		}

		[class*="h"] {
			text-decoration: underline dotted;
			color: $darkgray;
		}

		.is-active {
			a h2,
			h2:focus,
			h2:hover {
				color: $darkblue;
			}
		}

		.deeplink .ce-headline-center::after {
			display: inline-block;
			float: right;
			width: 10px;
			height: 10px;
			content: " ";
			width: 0;
			height: 0;
			margin-top: 1rem;
			border-left: 10px solid transparent;
			border-right: 10px solid transparent;
			border-top: 16px solid $darkgray;
		}

		.is-active .deeplink .ce-headline-center::after {
			border-left: 10px solid transparent;
			border-right: 10px solid transparent;
			border-bottom: 16px solid $darkgray;
			border-top: none;
		}

		.accordion-content {
			padding: 1.25rem 0 0;
			border: none;
		}
	}

	.ce-textpic .ce-gallery {
		margin: 0 0 2rem;
	}

	.ce-gallery {
		.ce-column {
			margin: 0;
			/* Abstand zwischen Bildern */
			padding: 0 5px 10px;
			box-sizing: border-box;
		}

		/* Kein Abstand beim ersten und letzten Bild */
		.ce-column:first-child {
			margin-left: 0;
		}

		.ce-column:last-child {
			margin-right: 0;
		}

		/* Fluid Image Tags */
		img,
		picture {
			//width: 100%;
			height: auto;
		}
	}

	/* Spaltenbreiten je nach eingestellten Columns */
	.ce-gallery[data-ce-columns="2"],
	.ce-gallery[data-ce-columns="3"],
	.ce-gallery[data-ce-columns="4"],
	.ce-gallery[data-ce-columns="5"] {
		.ce-column {
			display: inline-block;
			vertical-align: top;
		}
	}

	.ce-gallery[data-ce-columns="2"] .ce-column {
		width: 50%;
	}

	.ce-gallery[data-ce-columns="3"] .ce-column {
		width: 33%;
	}

	.ce-gallery[data-ce-columns="4"] .ce-column {
		width: 25%;
	}

	.ce-gallery[data-ce-columns="5"] .ce-column {
		width: 20%;
	}

	[class^="row element"].frame-space-before-abstandNullTop {
		padding-top: 0;
	}

	[class^="row element"].abstandNullBottom {
		margin: 0;
	}

	[class^="row element"].frame-space-after-abstandNullBottom {
		padding-bottom: 0;
	}

	[class^="row element"].frame-space-before-abstandTop {
		margin-top: 1rem;
	}

	[class^="row element"] .row.element2cols[class*="frame-space-after-abstandBottom"],
	[class^="row element"][class*=".frame-space-after-abstandBottom"] {
		margin-bottom: 1rem;
	}

	[class^="row element"] {
		//padding: 1rem 0;
		z-index: -10;

		.element1cols {
			padding: 2.5rem 0;
		}

		.row.element2cols {
			//padding: 0;
			margin: 0 auto;

			.columns:first-child {
				padding-left: 0;
				margin-left: 0;
			}

			.columns:last-child {
				padding-right: 0;
				margin-right: 0;
			}
		}

		.row.element3cols {
			padding: 0;
			margin: 0 auto;

			.columns:first-child {
				padding-left: 0;
				margin-left: 0;
			}

			.columns:nth-child(2) {
				padding: 0 .5rem;
			}

			.columns:last-child {
				padding-right: 0;
				margin-right: 0;
			}
		}

		.frame-space-before-abstandNullTop {
			padding-top: 0;
		}

		.abstandNullBottom {
			margin: 0;
		}

		.frame-space-after-abstandNullBottom {
			padding-bottom: 0;
		}

		.frame-space-after-abstandBottom.missiontothenorth header {
			margin-bottom: 0;
		}

		.frame-space-after-abstandBottom header {
			margin-bottom: 3.5rem;
		}

		.frame-space-before-abstandTop {
			margin-top: 3.5rem;
		}

		.frame-space-before-abstandShortContactTop {
			//margin-top: -.25rem;
		}

		//border: 1px solid red;
		h1,
		h2,
		h3,
		h4,
		h5,
		h6 {
			font-family: $body-font-familyBold;
			text-transform: uppercase;

			a::after {
				display: none;
				content: " ";
			}
		}

		.frame-type-textmedia {
			h3 {
				font-size: 1rem;
			}
		}

		.frame-box-ListStyle {
			a {
				display: block;
			}

			a::after {
				float: right;
				color: $darkblue;
				padding-right: 0;
			}
		}

		.frame-headline {
			h2,
			h3,
			h4,
			h5,
			h6 {
				text-decoration: underline dotted;
			}

			h2 + h3 {
				text-decoration: none;
				margin-bottom: 2.75rem;
			}
		}

		.frame-headlinebox {
			h2,
			h3,
			h4,
			h5,
			h6 {
				border-bottom: 2px dotted;
			}

			.ce-gallery + .ce-bodytext h4,
			h2 + h3 {
				border-bottom: none;
			}
		}

		.frame-box-linklist {
			ul {
				margin: 0;
			}
		}

		h3 {
			font-size: 1.625em;
		}

		h2 + h3 {
			font-size: 1.33em;
			font-family: $body-font-family;
		}

		h5 {
			font-size: 1.25rem;
		}

		h5.noborder {
			border: none;
		}

		h6 {
			font-size: 1.125rem;
		}

		.text-transform-none {
			text-transform: none;
		}

		.roboto-bold {
			font-family: $body-font-familyBold !important;
		}

		p.zitat {
			font-family: $body-font-familyNewCycle;
			font-size: 1.375em;
		}

		p {
			font-size: 1.125rem;
		}

		figure.image a::after {
			content: "";
			padding: 0;
		}

		/*
		a::after {
				content: "›››";
				padding: 0 1%;
		}*/
		.button {
			font-size: 1rem;
			padding: .85rem 3rem;
		}

		.button.green {
			background: $green;
			color: white;
			//border: 2px solid $green;
		}

		.button.green:focus,
		.button.green:hover {
			background: white;
			color: $green;
		}

		.float-right {
			float: right;
		}

		.frame-secondRow-25 {
			.tx-ttaddress {
				h5 {
					a {
						display: block;
					}
				}
			}

			.ce-bodytext {
				a::before {
					border: 2px solid $darkgray;
					border-radius: 100%;
					width: 15px;
					height: 15px;
					content: " ";
					display: inline-block;
					margin-right: 1.125rem;
					margin-top: .5rem;
					margin-left: -2.125rem;
					vertical-align: top;
				}

				a {
					color: $darkgray;
					text-transform: uppercase;
					font-size: 1.125rem;
					font-family: $body-font-familyBold;
					position: relative;
					display: block;
					margin-left: 2.125rem;
					position: relative;
					width: 80%;
				}

				a::after {
					font-family: "lmw-signets";
					content: "\e805";
					padding: 0;
					display: inline;
					border: none;
					margin: 0 0 0 1rem;
					color: $darkblue;
					position: absolute;
					right: -2rem;
					top: 0;
				}
			}

			ul {
				//margin-left: 2.125rem;
				a {
					margin-left: 0;
					font-family: $body-font-familyBold;
					width: 100%;
				}

				a::before {
					margin-right: 1rem;
					//margin-left: -2rem;
				}

				a::after {
					float: right;
					color: $darkblue;
				}

				a.extern {
					padding-left: 2rem;
				}

				a.extern::before {
					margin-left: -2rem;
				}
			}
		}

		a.download,
		a.extern,
		a.link-next-page {
			display: block;
		}

		a.download::after,
		a.extern::after,
		a.link-next-pageSide::after {
			font-family: "lmw-signets";
			float: right;
			color: $darkblue;
		}

		a.link-next-pageSide::after {
			content: "›››";
			font-family: $body-font-familyBold;
		}

		a.link-bordertop {
			width: 100%;
			border-top: 1px solid $lightgray;
			display: block;
			padding: .875rem 0 1.875rem;
		}

		a.download::after {
			content: '\e804';
		}

		a.extern::after {
			content: '\e805';
		}

		.link-next-page {
			display: block;
			color: $darkblue;
			padding: .25rem 0 .75rem;
		}

		.link-next-page:focus,
		.link-next-page:hover {
			color: $darkgray;
		}

		.backlink-wrap {
			color: $darkgray;
			font-size: 1.125rem;
			text-transform: uppercase;
			font-family: $body-font-familyBold;
			display: block;
			margin-top: 75px;
			text-align: center;
		}

		.backlink-wrap::before {
			content: "‹‹‹";
			margin-right: .5rem;
		}

		.backlink-wrap::after {
			display: none;
			content: "";
		}

		.ce-bodytext {
			ul {
				li {
					margin-bottom: 0;
					font-size: 1.125rem;
				}

				li::before {
					content: "-";
					margin-right: 1.25rem;
				}
			}
		}

		ul {
			margin-left: 0;

			li {
				list-style: none;
				margin-bottom: 1.25rem;

				a {
					//margin-left: -1.5rem;
					font-size: 1.125rem;
					font-family: $body-font-familyBold;
					text-transform: uppercase;
					color: $darkgray;

					span {
						width: 75%;
						max-width: 75%;
						word-wrap: anywhere;
						display: inline-block;
						color: $darkgray;
						line-height: normal;
					}
				}

				a:focus,
				a:focus span,
				a:hover,
				a:hover span {
					color: $darkblue;
				}

				a::before {
					border: 2px solid $darkgray;
					border-radius: 100%;
					width: 15px;
					height: 15px;
					content: " ";
					display: inline-block;
					margin-right: 1.125rem;
					margin-top: .5rem;
					vertical-align: top;
				}

				a::after {
					vertical-align: top;
				}
			}
		}

		.frame-type-uploads {
			.ce-uploads {
				margin-left: 0;

				a {
					width: 100%;
					display: block;

					.ce-uploads-fileName {
						//margin-left: 1.5rem;
						font-family: $body-font-familyBold;
					}
				}

				a::after {
					content: '\e804';
					font-family: "lmw-signets";
					float: right;
					padding: 0;
					color: $darkblue;
				}
			}
		}

		.white {
			background: white;
			color: $darkgray;
		}

		.white:focus,
		.white:hover {
			background: $darkgray;
			color: white;
		}

		.button::after {
			display: none;
		}

		@include orbitslider();

		.flexslider {
			border: none;
			margin-bottom: 0;

			ul {
				margin-left: 0;
			}

			.flex-direction-nav {
				a.flex-next,
				a.flex-prev {
					text-indent: -2000rem;
					margin-top: -30px;
					height: 80px;
				}

				.flex-next::after,
				.flex-next::before,
				.flex-prev::before {
					font-family: "lmw-signets";
					color: white;
					font-size: 40px;
				}

				.flex-prev::before {
					content: '\e801';
					margin-left: 0;
					border: none;
					text-indent: 2000rem;
				}

				.flex-prev::after {
					display: none;
					content: "";
				}

				.flex-next::before {
					display: none;
					content: "";
				}

				.flex-next::after {
					content: '\e802';
					border: none;
					margin-left: 2rem;
					text-indent: 2000rem;
				}
			}

		}

		/* Responsive Videos */
		.video-embed {
			position: relative;
			padding-bottom: 56.25%;
			padding-top: 30px;
			height: 0;
			overflow: hidden;
		}

		.video-embed embed,
		.video-embed iframe,
		.video-embed object,
		.video-embed video {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			border: none;
			margin: 0;
			padding: 0;
		}

		/* Workaround fuer Responsive Videos in Verbindung mit fluid_styled_content */
		.frame-layout-5 {
			.ce-center .ce-inner {
				position: relative;
				float: none;
				right: -50%;
			}

			.ce-gallery,
			.ce-image,
			.ce-nowrap .ce-bodytext,
			.ce-row,
			.ce-textpic,
			.ce-uploads div,
			.ce-uploads li {
				overflow: visible;
			}

			.ce-column,
			.ce-left .ce-gallery {
				float: none;
			}

			.ce-gallery figure {
				display: block;
				margin: 0;
			}

			.ce-center .ce-outer {
				position: relative;
				float: none;
				right: 50%;
			}
		}

		.frame-layout-6,
		.frame-layout-7,
		.frame-layout-8,
		.frame-layout-9 {
			display: inline-block;
			width: 20px;
			height: 20px;
			float: left;

			hr {
				display: none;
			}
		}

		.frame-layout-6 {
			background: $lightblue;
		}

		.frame-layout-7 {
			background: $blue;
		}

		.frame-layout-8 {
			background: $darkblue;
		}

		.frame-layout-9 {
			background: $green;
		}

		.frame-space-before-abstandLogos {
			margin: 1em auto;

			.ce-row {
				display: flex;
				justify-content: space-around;
				flex-wrap: wrap;

				.ce-column {
					display: inline-block;
					text-align: center;
					flex: 3 1 0;
				}
			}
		}

		[class*="frame-icon-"] header h4::before {
			font-family: 'lmw-signets';
			display: block;
		}

		.frame-icon-begegnen header h4::before {
			content: '\e806';
		}

		.frame-icon-bilden header h4::before {
			content: '\e807';
		}

		.frame-icon-engagieren header h4::before {
			content: '\e808';
		}

		.frame-icon-spenden header h4::before {
			content: '\e80b';
		}

		.tx-sic-address {
			.listview {
				padding: 0 .9375rem;

				#sic_address_search_form {
					margin: 1.25rem auto 4.5rem;
					padding-bottom: 2rem;
					border-bottom: 1px solid $lightgray;
					width: 100%;
				}

				a,
				span {
					color: $darkgray;
				}

				a:focus,
				a:hover {
					color: $darkblue;
				}

				.abisz {
					display: flex;
					height: 22px;
					text-align: center;

					a,
					span {
						flex: 1 0 auto;
						font-family: $body-font-familyBold;
						font-size: 1.5rem;
						line-height: normal;
						padding-top: .25rem;
					}

					a::after,
					span::after {
						content: "";
						border-right: 2px solid $darkgray;
						margin: 0 0 0 .5rem;
					}

					a:last-child::after,
					span:last-child::after {
						border-right: none;
					}

					.activelinkcolor {
						color: $darkblue;
					}

					.deadlinkcolor {
						color: $lightgray;
					}
				}

				.pulldown {
					.category {
						margin: 0;
						padding: 0;

						select {
							margin-bottom: 0;
						}
					}
				}

				.vcard {
					margin: .5rem;

					p a::after {
						content: "";
						display: none;
					}

					.addressImage {
						margin: 0 .5rem 0 0;

						[class*="-3"] {
							flex: auto;
							margin: 0;
							width: 100%;
							max-width: 100%;

							figure {
								margin: 0;

								img {
									width: 100%;
								}
							}
						}
					}

					a {
						font-size: 1.125rem;
						font-family: $body-font-familyBold;
					}

					.text-center {
						margin: 0 auto;

						figure {
							margin: 3.75rem auto;
						}
					}

					.specialdays {
						margin-bottom: 2rem;
					}
				}

				.vcard:nth-child(2n+1) {
					padding: 2.5rem .5rem 1rem;
				}

				.vcard:nth-child(2n) {
					padding: 2.5rem .5rem 1rem;
				}
			}
		}

		.publikationen {
			.large-up-3 .cell {
				width: calc(33.33333% - 1.25rem);
			}

			.cell {
				margin: 0 .625rem 3rem;
				border-bottom: 3px dotted $darkgray;

				.callout {
					border: none;
					padding: 0;
					height: 100%;

					.publikation-content {
						min-height: 18rem;
					}

					h3 {
						text-transform: uppercase;
					}

					h6 {
						a {
							color: $darkgray;
						}

						a:focus,
						a:hover {
							color: $darkblue;
						}

						a::before {
							border: 2px solid $darkgray;
							border-radius: 100%;
							width: 15px;
							height: 15px;
							content: " ";
							display: inline-block;
							margin-right: 1.125rem;
							margin-top: .25rem;
							vertical-align: top;
						}

						a::after {
							display: block;
						}
					}

					figure {
						border-top: 1px dotted $lightgray;

						img {
							width: 300px;
							margin: 1.975rem auto;
							box-shadow: $boxshadow-centered;
							display: block;
						}
					}
				}
			}
		}

		.roundedImage {
			border-radius: 100%;
			overflow: hidden;
			padding: 0;
			margin: 0 .975rem;
			width: 10rem;
			height: 10rem;
			max-width: 10rem;
		}
	}

	.element1cols {
		padding: 1em 0 1.25em;
		overflow: hidden;

		.searchbox {
			width: 100%;

			ul {
				li:first-child {
					width: 100%;
				}

				li button {
					padding: .7rem 1rem;
				}

				input {
					height: 2.5rem;
				}
			}
		}
	}

	.row.frame-darkBackground-jumbotron,
	.row.frame-jumbotron {
		padding: 7.5em 0 8em;
		margin: 0;
	}

	.frame-darkBackground-jumbotron {
		h1,
		h2,
		h3,
		h4,
		h5,
		h6,
		li,
		p {
			color: white;

			a {
				color: white;
			}

			a::after {
				content: "";
				display: none;
			}
		}
	}

	.row .row.element2cols {
		max-width: 73rem;
		margin: 0 auto;

		[class*="frame-icon-"] {
			padding: 1em 1em 0;
		}

		[class*="frame-icon-"]::before {
			font-family: 'lmw-signets';
			display: block;
			font-size: 5.5em;
			text-align: center;
			color: $green;
			background: white;
			border-radius: 100%;
			width: 1em;
			height: 1em;
			margin: .25em auto;
			padding: 0;
			line-height: normal;
		}

		.frame-icon-begegnen::before {
			content: '\e800';
		}

		.frame-icon-bilden::before {
			content: '\e805';
		}

		.frame-icon-engagieren::before {
			content: '\e806';
		}

		.frame-icon-spenden::before {
			content: '\e808';
		}
	}
}

@media only screen and (min-width: 1024px) {
}

@media only screen and (max-width: 1023px) {
}

/** ------------- https://css-tricks.com/snippets/css/media-queries-for-standard-devices/ ------------- **/
/** gültig für alle Geräte **/
/* -------------------------------------------- */
/* iPhone 2G-4S in portrait & landscape */
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
	html body {
		.news [class^="news-list-view"] {
			display: block;
			grid-template-columns: auto;

			.frame-type-textmedia .ce-bodytext {
				padding: 0 .9375rem;
			}

			.article {
				margin-bottom: 1.125rem;
				grid-column: auto !important;
			}
		}

		.news-single {
			.article .article-body,
			.article .article-header {
				display: block;

				.frame-firstRow-66 {
					margin: 0 0 1.5rem;
				}

				.ce-textpic {
					display: block;
					padding: 0 .9375rem;
				}
			}

			/*
			.orbit.aktuelles .orbit-wrapper .orbit-container {
					.orbit-slide {
							height: auto;
							width: 100%;

							.orbit-figure {
									height: auto;
									width: 100%;

									.orbit-image {
											height: auto;
											width: 100%;
									}
							}
					}
			}
			*/
		}
	}
}

/* iPhone 2G-4S in landscape */
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (orientation: landscape) {
}

/* iPhone 2G-4S in portrait */
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (orientation: portrait) {
}

/* -------------------------------------------- */
/* iPhone 5 Media Queries */
/* iPhone 5 & 5S in portrait & landscape */
@media only screen and (min-device-width: 320px) and (max-device-width: 568px) {
	html body {
		.news [class^="news-list-view"] {
			display: block;
			grid-template-columns: auto;

			.frame-type-textmedia .ce-bodytext {
				padding: 0 .9375rem;
			}

			.article {
				margin-bottom: 1.125rem;
				grid-column: auto !important;
			}
		}

		.news-single {
			.article .article-body,
			.article .article-header {
				display: block;

				.frame-firstRow-66 {
					margin: 0 0 1.5rem;
				}

				.ce-textpic {
					display: block;
					padding: 0 .9375rem;
				}
			}
		}

		[class^="row element"] .frame-space-before-abstandLogos {
			.ce-row {
				display: flex;
				justify-content: space-around;

				.ce-column {
					display: inline-block;
					text-align: center;
					flex: 100% 0 0;
				}
			}
		}
	}
}

/* iPhone 5 & 5S in landscape */
@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2.0) {
}

/* iPhone 5 & 5S in portrait */
@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2.0) {
}

/* -------------------------------------------- */
/* iPhone 6 Media Queries */
/* iPhone 6 in portrait & landscape */
@media only screen and (min-device-width: 375px) and (max-device-width: 667px) {
	html .news-single .article .article-body {
		display: block;

		.frame-secondRow-25 {
			.ce-bodytext {
				a {
					width: auto;
				}

				a::after {
					right: 0;
				}
			}
		}

		.tx-ttaddress .tt_address_list.shortContact {
			padding: 0;
		}
	}
}

/* iPhone 6 in landscape */
@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (orientation: landscape) {
}

/* iPhone 6 in portrait */
@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (orientation: portrait) {
}

/* iPhone 6 Plus in portrait & landscape */
@media only screen and (min-device-width: 414px) and (max-device-width: 736px) {
}

/* iPhone 6 Plus in landscape */
@media only screen and (min-device-width: 414px) and (max-device-width: 736px) and (orientation: landscape) {
	html .news-single .article .article-body {
		display: block;

		.frame-secondRow-25 {
			.ce-bodytext {
				a {
					width: auto;
				}

				a::after {
					right: 0;
				}
			}
		}

		.tx-ttaddress .tt_address_list.shortContact {
			padding: 0;
		}
	}
}

/* iPhone 6 Plus in portrait */
@media only screen and (min-device-width: 414px) and (max-device-width: 736px) and (orientation: portrait) {
}

/* -------------------------------------------- */
/* generell 812 x 375 portrait & landscape für iPhone11 */
@media only screen and (min-width: 375px) and (max-width: 812px) {
	html .news-single .article .article-body {
		display: block;

		.frame-secondRow-25 {
			.ce-bodytext {
				a {
					width: auto;
				}

				a::after {
					right: 0;
				}
			}
		}
	}
}

@media only screen and (min-width: 375px) and (max-width: 812px) and (orientation: portrait) {
}

@media only screen and (min-width: 375px) and (max-width: 812px) and (orientation: landscape) {
}

/* -------------------------------------------- */
/* generell 768 x 1024 portrait & landscape */
@media only screen and (min-width: 768px) and (max-width: 1024px) {
}

@media only screen and (min-width: 737px) and (max-width: 1024px) and (orientation: portrait) {
}

@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
}

/* -------------------------------------------- */
/* iPad mini 1 & 2 in portrait & landscape */
@media only screen and (min-width: 768px) and (max-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
}

/* iPad mini in landscape */
@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {
}

/* iPad mini in portrait */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
}

/* -------------------------------------------- */
/* iPad mini 3 in portrait & landscape */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) {
}

/* iPad mini in landscape */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
}

/* iPad mini in portrait */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
}

/* -------------------------------------------- */
/* iPad in portrait & landscape */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
}

/* iPad in landscape */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
}

/* iPad in portrait */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
	html {
		[class^="row element"] {
			.frame-secondRow-25 {
				.ce-bodytext {
					a::after {
						right: -.75rem;
					}
				}
			}
		}
	}
}

/* -------------------------------------------- */
/* iPad 1 & 2 in portrait & landscape */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
}

/* iPad 1 & 2 in landscape */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {
}

/* iPad 1 & 2 in portrait */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
}

/* -------------------------------------------- */
/* iPad 3 & 4 in portrait & landscape */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) {
}

/* iPad 3 & 4 in landscape */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
}

/* iPad 3 & 4 in portrait */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
}

/* -------------------------------------------- */
/* iPad Pro in portrait & landscape */
/*only screen and (width: 678px) and (height: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2)/* and (device-width: 1366px)  /*and (-webkit-min-device-pixel-ratio: 2)*/
@media only screen and (min-height: 981px) and (max-height: 1366px) and (min-width: 981px) and (max-width: 1366px) and (-webkit-min-device-pixel-ratio: 2), only screen and (min-height: 1024px) and (max-height: 1366px) and (min-width: 1024px) and (max-width: 1366px) and (-webkit-min-device-pixel-ratio: 2) {
}

/* iPad Pro Portrait */
/*and (-webkit-min-device-pixel-ratio: 2)*/
@media only screen and (width: 1024px) and (height: 1366px) and (orientation: portrait) {
}

/* iPad Pro Landscape */
/*and (-webkit-min-device-pixel-ratio: 2)*/
@media only screen and (height: 1024px) and (width: 1366px) and (orientation: landscape) {
}

/* iPad Pro Teilscreen */
/*and (-webkit-min-device-pixel-ratio: 2)*/
@media only screen and (width: 981px) and (height: 1024px) {
}

/* später aktivieren */
/* iPad Pro Teilscreen */
/* and (device-width: 1366px)  /*and (-webkit-min-device-pixel-ratio: 2)*/
@media only screen and (width: 678px) and (height: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
}

/* andere hochauflösende Displays */
@media screen and (-webkit-min-device-pixel-ratio: 3.0) and (min-width: 800px) and (max-width: 1280px) {
}

@media screen and (-webkit-min-device-pixel-ratio: 3.0) and (min-width: 800px) and (max-width: 1280px) and (orientation: landscape) {
}

/* ----------- Galaxy Tab 10.1 ----------- */
/* Portrait and Landscape */
@media only screen and (min-width: 800px) and (max-width: 1280px) {
}

/* Portrait */
@media only screen and (max-width: 800px) and (orientation: portrait) {
}

/* Landscape */
@media only screen and (max-width: 1280px) and (orientation: landscape) {
}

/* ----------- Asus Nexus 7 ----------- */
/* Portrait and Landscape */
@media only screen and (device-width: 601px) and (device-height: 906px) and (-webkit-min-device-pixel-ratio: 1.331) and (-webkit-max-device-pixel-ratio: 1.332) {
}

/* Portrait */
@media only screen and (device-width: 601px) and (device-height: 906px) and (-webkit-min-device-pixel-ratio: 1.331) and (-webkit-max-device-pixel-ratio: 1.332) and (orientation: portrait) {
}

/* Landscape */
@media only screen and (device-width: 601px) and (device-height: 906px) and (-webkit-min-device-pixel-ratio: 1.331) and (-webkit-max-device-pixel-ratio: 1.332) and (orientation: landscape) {
}

/* ----------- Kindle Fire HD 7" ----------- */
/* Portrait and Landscape */
@media only screen and (min-width: 800px) and (max-width: 1280px) and (-webkit-min-device-pixel-ratio: 1.5) {
}

/* Portrait */
@media only screen and (min-width: 800px) and (max-width: 1280px) and (-webkit-min-device-pixel-ratio: 1.5) and (orientation: portrait) {
}

/* Landscape */
@media only screen and (min-width: 800px) and (max-width: 1280px) and (-webkit-min-device-pixel-ratio: 1.5) and (orientation: landscape) {
}

/* ----------- Kindle Fire HD 8.9" ----------- */
/* Portrait and Landscape */
@media only screen and (min-width: 1200px) and (max-width: 1600px) and (-webkit-min-device-pixel-ratio: 1.5) {
}

/* Portrait */
@media only screen and (min-width: 1200px) and (max-width: 1600px) and (-webkit-min-device-pixel-ratio: 1.5) and (orientation: portrait) {
}

/* Landscape */
@media only screen and (min-width: 1200px) and (max-width: 1600px) and (-webkit-min-device-pixel-ratio: 1.5) and (orientation: landscape) {
}

@media only screen and (min-width: 1850px) {
}

@media only screen and (min-aspect-ratio: 1/1) and (max-aspect-ratio: 16/10) {
}
