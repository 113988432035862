@font-face {
	font-family: 'lmw-signets';
	src: url("../Fonts/lmw-signets/lmw-signets.eot?81034449");
	src: url("../Fonts/lmw-signets/lmw-signets.eot?81034449#iefix") format('embedded-opentype'), url("../Fonts/lmw-signets/lmw-signets.woff2?81034449") format('woff2'), url("../Fonts/lmw-signets/lmw-signets.woff?81034449") format('woff'), url("../Fonts/lmw-signets/lmw-signets.ttf?81034449") format('truetype'), url("../Fonts/lmw-signets/lmw-signets.svg?81034449#lmw-signets") format('svg');
	font-weight: normal;
	font-style: normal;
}

@mixin orbitslider() {
	.textimage-slider {
		.orbit-previous,
		.orbit-next {
			background: $gray_transparent;
			// height: 100%;
			margin: 0 1rem;
			//text-indent: -2000rem;

			&:hover, &:focus, &:active {
				background: none;
				color: white;
				text-indent: 0;
				transition: all 0.3s ease-in-out;
				&::before, &::after {
					color: white;
				}
			}

			&::before, &::after {
				color: $white_transparent;
				content: '\e801';
				// background: white;
				font-family: "lmw-signets";
				font-size: 40px;
			}
		}

		.orbit-figure {
			display: block;
			height: 100%;
			position: relative;

			picture {
				display: block;
				height: 100%;
			}
		}

		.orbit-previous::before {
			content: '\e801';
			margin-left: 0;
			border: none;
			text-indent: 2000rem;
		}

		.orbit-previous::after {
			display: none;
			content: "";
		}

		.orbit-next::after {
			content: '\e802';
		}

		.orbit-next::before {
			display: none;
			content: "";
		}

		.caption-body {
			p {
				font-size: 1.125rem;
				font-family: $body-font-familyBold;
				text-transform: uppercase;
				color: #3d3d3f;

				a:focus,
				a:hover {
					text-decoration: underline;
				}

				a::before, a::after {
					content: "";
				}
			}
		}

		.orbit {
			@include breakpoint(large down) {
				overflow: hidden;
			}

			.orbit-container {
				height: 335px !important;

				&.image {
					height: 290px !important;
				}

				@include breakpoint(large down) {
					height: auto !important;
				}

				.orbit-slide {
					align-items: flex-end;
					display: flex;
					height: 100%;
					padding: 0 1rem;
					margin: 0;

					@include breakpoint(large down) {
						position: relative;
					}

					[class*="caption-align-"] {

						.orbit-caption {
							background: $blue;
							bottom: auto;
							box-shadow: $boxshadow;
							top: 5.5rem;
							width: 37.5em;

							@include breakpoint(large down) {
								position: relative;
								top: auto;
								left: auto !important;
								right: auto !important;
								width: 100%;
							}

							.caption-title {
								color: $white;
								font-size: 2.25rem;
								margin-bottom: 0;
							}

							a, h1, h2, h3, h4, h5, h6, p {
								color: $white;

								a::before {
									content: "";
									display: none;
								}
							}
						}
					}

					.caption-align-left .orbit-caption {
						left: -8em;
						padding-left: 11em;
					}

					.caption-align-right .orbit-caption {
						right: -8em;
						padding-right: 11em;
					}

					@include breakpoint(large down) {
						.caption-align-left, .caption-align-right {
							.orbit-caption {
								left: auto;
								right: auto;
								padding-left: 1rem;
								padding-right: 1rem;
							}
						}
					}
				}
			}
		}

		.orbit-bullets {
			bottom: rem-calc(10px);
			// top: 5rem;
			padding: 0 1rem;
			position: absolute;
			right: 0;
			width: auto;
			z-index: 100;
			@include breakpoint(large down) {
				bottom: rem-calc(20px);
			}

			a::after,
			a::before {
				content: "";
				display: none;
			}

			button {
				background: none;
				border: 3px solid $blue;
				border-radius: 100%;
				box-shadow: none;
				width: rem-calc(11px);
				height: rem-calc(11px);
				display: inline-block;
			}

			button.is-active {
				background: $blue;
			}
		}
	}
}